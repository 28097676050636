// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

const eventLogger = (event, error) => {
  console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens) => {
  console.log('onKeycloakTokens', tokens);
};

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    onEvent={eventLogger}
    onTokens={tokenLogger}
    initOptions={{
      onLoad: 'login-required', // Automatically redirect to login if not authenticated
      checkLoginIframe: false, // Disable periodic token checks
      pkceMethod: 'S256',
      enableLogging: true,
    }}
  >
    <App />
  </ReactKeycloakProvider>,
  document.getElementById('root')
);