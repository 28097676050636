// App.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, CircularProgress, Box } from '@mui/material';
import SearchForm from './SearchForm';
import ResultsDisplay from './ResultsDisplay';
import axios from 'axios';
import keycloak from './keycloak';


function App() {
  const [taskId, setTaskId] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [expectedResults, setExpectedResults] = useState(0);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);

  const api_url = 'https://clavis-api-v2.emergo.dev/api'
  //const api_url = 'http://localhost:8000/api'
  // Polling the status and results every 2 seconds
  useEffect(() => {
    let interval = null;
    if (taskId) {
      interval = setInterval(() => {
        axios
          .get(`${api_url}/status/${taskId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
          .then((response) => {
            setIsComplete(response.data.is_complete);
            setExpectedResults(response.data.expected_results); // Assuming backend provides this
            setResultsCount(response.data.results_count);

            // Fetch the current results
            fetchResults();

            if (response.data.is_complete) {
              clearInterval(interval);
            }
          })
          .catch((err) => {
            setError('An error occurred while checking status.');
            clearInterval(interval);
          });
      }, 2000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const fetchResults = () => {
    axios
      .get(`${api_url}/results/${taskId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
      .then((response) => {
        setResults(response.data);
      })
      .catch((err) => {
        setError('An error occurred while fetching results.');
      });
  };

  const handleSearch = (query, numResults) => {
    // Reset state
    setTaskId(null);
    setIsComplete(false);
    setResultsCount(0);
    setExpectedResults(0);
    setResults([]);
    setError(null);

    axios
      .post(`${api_url}/search`, {
        query: query,
        num_results: numResults,
      }, { headers: { Authorization: `Bearer ${keycloak.token}` } })
      .then((response) => {
        setTaskId(response.data.task_id);
        setExpectedResults(numResults); // Set expected results based on user input
      })
      .catch((err) => {
        setError('An error occurred while starting the search.');
      });
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h3" align="center" gutterBottom>
        Project Stakeholder Analyzer
      </Typography>
      <SearchForm onSearch={handleSearch} />
      {error && (
        <Typography color="error" align="center" style={{ marginTop: '1rem' }}>
          {error}
        </Typography>
      )}
      {taskId && (
        <Box marginTop="2rem">
          {!isComplete && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
              <Typography variant="h6" style={{ marginLeft: '1rem' }}>
                Processing your request... ({resultsCount}/{expectedResults} results)
              </Typography>
            </Box>
          )}
          {results.length > 0 && <ResultsDisplay results={results} />}
        </Box>
      )}
    </Container>
  );
}

export default App;