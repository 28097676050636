// SearchForm.js
import React, { useState } from 'react';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchForm({ onSearch }) {
    const [query, setQuery] = useState('');
    const [numResults, setNumResults] = useState(5);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (query.trim() !== '') {
            onSearch(query, numResults);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={8}>
                    <TextField
                        fullWidth
                        label="Search Term"
                        variant="outlined"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Number of Results"
                        type="number"
                        variant="outlined"
                        value={numResults}
                        onChange={(e) => setNumResults(e.target.value)}
                        inputProps={{ min: 1, max: 20 }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" type="submit" size="large">
                        Start Analysis
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default SearchForm;