// ResultsDisplay.js
import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemText,
    Link as MuiLink,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function ResultsDisplay({ results }) {
    return (
        <div style={{ marginTop: '2rem' }}>
            <Typography variant="h4" gutterBottom>
                Analysis Results
            </Typography>
            <Grid container spacing={4}>
                {results.map((result, index) => (
                    <Grid item xs={12} key={index}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Result {index + 1}
                                </Typography>

                                {/* Display URL as a clickable link with an icon */}
                                {result.url && (
                                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                        Source:{' '}
                                        <MuiLink
                                            href={result.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="hover"
                                            style={{ display: 'inline-flex', alignItems: 'center' }}
                                        >
                                            {result.url}
                                            <OpenInNewIcon fontSize="small" style={{ marginLeft: '0.25rem' }} />
                                        </MuiLink>
                                    </Typography>
                                )}

                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    Summary
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {result.summary}
                                </Typography>

                                <Divider style={{ margin: '1rem 0' }} />

                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    Stakeholders
                                </Typography>
                                {result.stakeholders.length > 0 ? (
                                    <Grid container spacing={1}>
                                        {result.stakeholders.map((stakeholder, idx) => (
                                            <Grid item key={idx}>
                                                <Chip
                                                    label={stakeholder.name}
                                                    variant="outlined"
                                                    color="primary"
                                                    clickable
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        No stakeholders identified.
                                    </Typography>
                                )}

                                <Divider style={{ margin: '1rem 0' }} />

                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    Quotes
                                </Typography>
                                {result.quotes.length > 0 ? (
                                    <List>
                                        {result.quotes.map((quote, idx) => (
                                            <ListItem key={idx} alignItems="flex-start">
                                                <ListItemText
                                                    primary={`"${quote.quote}"`}
                                                    secondary={
                                                        <>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                color="textPrimary"
                                                            >
                                                                - {quote.speaker}
                                                            </Typography>
                                                            {` — Sentiment: ${quote.sentiment}`}
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        No quotes identified.
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default ResultsDisplay;